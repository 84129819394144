import React from 'react'
import SEO from "../components/seo"
import { Header } from '../components/header'
import css from './subfield.module.css';
import { graphql, Link } from 'gatsby';
import { Footer } from '../components/footer';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { FullStarIcon, HalfStarIcon, EmptyStarIcon } from '../components/logos';

export const slugify = (res) =>
    `${res.name}-by-${res.authors.split(', ').slice(0, 5).join('-and-')}${res.authors.split(', ').length > 5 ? '-and-others' : ''}`.replace(/ /g, '-').replace(/:/g, '').replace(/\./g, '').replace(/’/g, '').replace(/,/g, '').toLowerCase()


export const voteToStars = (vote, size) => {
    if (vote < 1.5) return <><FullStarIcon size={size} /><EmptyStarIcon size={size} /><EmptyStarIcon size={size} /></>;
    if (vote < 2) return <><FullStarIcon size={size} /><HalfStarIcon size={size} /><EmptyStarIcon size={size} /></>;
    if (vote < 2.5) return <><FullStarIcon size={size} /><FullStarIcon size={size} /><EmptyStarIcon size={size} /></>;
    if (vote < 2.9) return <><FullStarIcon size={size} /><FullStarIcon size={size} /><HalfStarIcon size={size} /></>;
    if (vote <= 3) return <><FullStarIcon size={size} /><FullStarIcon size={size} /><FullStarIcon size={size} /></>;
}

export default ({ pageContext: { subfield, field }, data: { resourcesYaml: {resources} } }) => {
    return (
        <>
            <SEO title={`Free ${subfield.name} Resources - ${resources.map(resource => resource.type).join(', ')}`} />
            <Header />
            <h2 className={css.header}>{subfield.name} Resources</h2>
            <nav className={css.nav}>
                {
                    resources.length > 1 &&
                    <ul className={css.navList}>
                        {resources.map(resource => <li className={css.navLinkWrapper}>
                            <AnchorLink className={css.navLink} to={`${field.slug}/${subfield.slug}/#${resource.type.toLowerCase().replace(/ /g, '-')}`}>{resource.type}</AnchorLink>
                        </li>)}
                    </ul>
                }
                <div>
                    {resources.map(resource => (
                        <div id={resource.type.toLowerCase().replace(/ /g, '-')}>
                            <h3 className={css.resourceListHeader}>{resource.type}</h3>
                            <ul className={css.resourcesList}>
                                {resource.list.map(res => {
                                    const resourceSlug = slugify(res);
                                    return (
                                        <li className={css.resource}>
                                            <div>
                                                <h4 className={css.resourceHeaderWrapper}>
                                                    <Link to={`${field.slug}/${subfield.slug}/${resourceSlug}`} className={css.resourceHeader}>{res.name}</Link>
                                                </h4>
                                                <div className={css.resourceAuthor}>{res.authors}</div>
                                                <ul className={css.tags}>{(res.tags || []).map(tag => 
                                                    <li className={css.tag}>
                                                        {tag}
                                                    </li>)}
                                                </ul>
                                            </div>
                                        </li>)
                                    })}
                            </ul>
                        </div>
                    ))}
                </div>
            </nav>
            <Footer />
        </>
    );
};

export const pageQuery = graphql`
query($subfieldSlug: String!) {
    resourcesYaml(slug: {eq: $subfieldSlug}) {
      slug
      resources {
        type
        list {
          authors
          name
          tags
        }
      }
    }
  }
`;
